import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const PersonCard = ({ name, title, image, bio, links }) => (
    <Card
        sx={{
            display: "flex",
            height: "100%",
            flexDirection: "column"
        }}
        elevation={0}
    >
        <CardMedia
            component="div"
            sx={{
                backgroundImage: `url(${image.publicURL})`,
                backgroundSize: "cover",
                height: {
                    xs: "200px",
                    md: "360px"
                },
                width: "100%",
                backgroundPosition: "top",
                backgroundRepeat: "no-repeat"
            }}
            alt={`Photograph of ${name}, ${title}`}
        />
        <CardContent>
            <Box display="flex" justifyContent="space-between">
                <Box>
                    <Typography
                        variant="h6"
                        sx={{ fontSize: { xs: "1.125rem", md: "h6.fontSize" } }}
                    >
                        {name}
                    </Typography>
                    <Typography
                        lineHeight="1.4"
                        variant="subtitle1"
                        sx={{ fontSize: { xs: "0.875rem", md: "0.95rem" } }}
                    >
                        {title}
                    </Typography>
                </Box>
                {links?.linkedIn && (
                    <Fab
                        size="small"
                        sx={{
                            backgroundColor: "#0077CC",
                            color: "white",
                            boxShadow: "none"
                        }}
                        href={links.linkedIn}
                        target="_blank"
                    >
                        <LinkedInIcon />
                    </Fab>
                )}
            </Box>
            <Typography
                variant="caption"
                fontFamily="Hind"
                fontSize="0.95rem"
                lineHeight="1.4"
                fontWeight={300}
            >
                {bio}
            </Typography>
        </CardContent>
    </Card>
);

PersonCard.propTypes = {
    /**
     * Person's name
     */
    name: PropTypes.string.isRequired,
    /**
     * Person's job title
     */
    title: PropTypes.string.isRequired,
    /**
     * Profile photo of the person
     */
    image: PropTypes.object.isRequired,
    /**
     * Short biography of the person
     */
    bio: PropTypes.node.isRequired,
    /**
     * Optional object containing social media links for the person
     */
    links: PropTypes.shape({
        linkedIn: PropTypes.string
    })
};

PersonCard.defaultProps = {
    links: {}
};

export default PersonCard;
