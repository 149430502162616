import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import * as React from "react";

import ComingSoonBanner from "../components/ComingSoonBanner";
import ContentContainer from "../components/ContentContainer";
import IntermediariesContact from "../components/IntermediariesContact";
import Layout from "../components/Layout";
import PersonCard from "../components/PersonCard";
import SEO from "../components/SEO";
import HouseInClouds from "../images/HouseInClouds.png";
import Question from "../images/Question.png";
import RightArrow from "../images/RightArrow.png";
import UpArrow from "../images/UpArrow.png";

const { useMemo } = React;

const MODA_FEATURES = {
    SMARTER: [
        {
            title: "We add depth, variety and diversity to your business offering",
            description:
                "From limited companies to portfolio landlords, HMOs to MUFBs, and from first time landlords and first time buyers to existing landlords, our specialism helps to boost your client offering."
        },
        {
            title: "Informed, expert people",
            description:
                "We’ve brought together a team of experts, with up-to-date knowledge and industry experience enabling them to help you."
        },
        {
            title: "A safe pair of hands",
            description:
                "We’re part of a strong, secure and stable bank that’s well funded, so you know we’ll always be open for business."
        }
    ],
    FASTER: [
        {
            title: "Stay on top of it all",
            description:
                "We think fast and work fast, so we can continually update our products to keep pace with the market and provide customer-focused solutions."
        },
        {
            title: "Speed things up",
            description:
                "With no legacy systems, we’ve been able to develop slick internal processes that support your own processes. We help speed you up, not slow you down, backed by a simple and easy online application process."
        },
        {
            title: "Transparent decisions for better solutions",
            description:
                "We’re first on the scene to help with transparent decisions so you can find a suitable specialist lending solution for your clients."
        }
    ],
    SIMPLER: [
        {
            title: "Decisive decisioning",
            description:
                "Upfront processing of affordability enables a quick yes/no decision in principle."
        },
        {
            title: "Talk to your clients with confidence",
            description:
                "Straight-talking criteria with no surprises. That way, you can be confident in what you’re telling your clients."
        },
        {
            title: "Get in touch, your way",
            description:
                "Direct access to experts when needed — you choose the best way for you. Telephone, face-to-face, live web chat, case tracking. We provide office-based broker support from experienced professionals too. And our experienced field-based relationship team are always ready for a chat."
        }
    ]
};

const AboutUs = ({ data, ...rest }) => {
    const managementTeam = useMemo(
        () =>
            data.managementTeam.nodes.map((node) => ({
                name: node.frontmatter.name,
                title: node.frontmatter.title,
                image: node.frontmatter.photo,
                // eslint-disable-next-line react/no-danger
                bio: <div dangerouslySetInnerHTML={{ __html: node.html }} />,
                links: node.frontmatter.links
            })),
        [data]
    );

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

    return (
        <Layout title="Smarter. Faster. Simpler.">
            <ComingSoonBanner />
            <Box pb={4}>
                <ContentContainer
                    image={
                        <Box
                            component="img"
                            alt="House in clouds"
                            src={HouseInClouds}
                            sx={{
                                width: "100%"
                            }}
                        />
                    }
                >
                    <Typography
                        variant="h1"
                        component="h1"
                        sx={{
                            mt: {
                                xs: 2,
                                md: 6,
                                lg: 8
                            },
                            mb: {
                                xs: 0,
                                sm: 2,
                                md: 4
                            },
                            fontSize: {
                                xs: "1.625rem",
                                sm: "2.375rem"
                            },
                            fontWeight: "500"
                        }}
                    >
                        We’re ModaMortgages from Chetwood Financial, a modern kind of bank
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{
                            fontFamily: "Hind",
                            lineHeight: "1.35",
                            fontSize: "1.125rem",
                            fontWeight: "300"
                        }}
                    >
                        <p>
                            ModaMortgages was created by the UK digital bank, Chetwood Financial,
                            whose focus since 2016 has been to use technology and highly targeted
                            products to make people better off.
                        </p>
                        <p>
                            Chetwood has launched a number of brands across savings and unsecured
                            credit for customers with complex needs, and we’re now bringing our
                            approach and expertise to the specialist buy to let mortgage market.
                        </p>
                        <p>
                            We’re backed by Elliott Advisors (UK) Limited and generate funding using
                            retail deposits through our savings brand SmartSave.
                        </p>
                    </Typography>
                    <Box
                        component="a"
                        href="https://chetwood.co"
                        target="_blank"
                        rel="noreferrer"
                        sx={{ color: "text.primary", fontWeight: "500" }}
                    >
                        Visit Chetwood Financial
                    </Box>
                </ContentContainer>
            </Box>

            <Box
                bgcolor="#EBEBE1"
                sx={{
                    pt: {
                        xs: 2,
                        md: 8
                    },
                    pb: {
                        xs: 2,
                        md: 14
                    }
                }}
            >
                <ContentContainer>
                    <Typography
                        variant="h1"
                        component="h1"
                        sx={{
                            mt: {
                                xs: 2,
                                md: 8
                            },
                            mb: {
                                xs: 2,
                                sm: 4,
                                xl: 0
                            },
                            fontSize: { xs: "1.75rem", sm: "1.875rem" },
                            fontWeight: "500"
                        }}
                    >
                        Why ModaMortgages
                    </Typography>
                </ContentContainer>

                <ContentContainer
                    image={
                        <Box
                            component="img"
                            alt="Question mark"
                            src={Question}
                            sx={{
                                width: "100%"
                            }}
                        />
                    }
                >
                    <Typography
                        variant="h1"
                        component="h1"
                        sx={{
                            mb: 4,
                            fontSize: "1.625rem",
                            fontWeight: "500"
                        }}
                    >
                        Smarter:
                    </Typography>

                    <Box>
                        {MODA_FEATURES.SMARTER.map(({ title, description }) => (
                            <Box mb={3} key={title}>
                                <Typography
                                    sx={{
                                        fontSize: "1.375rem",
                                        fontWeight: 500,
                                        mb: 2.5
                                    }}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: "Hind",
                                        lineHeight: "1.35",
                                        fontSize: "1.125rem"
                                    }}
                                >
                                    {description}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </ContentContainer>

                <ContentContainer
                    reverse={!isMobile}
                    image={
                        <Box
                            component="img"
                            alt="Right arrow"
                            src={RightArrow}
                            sx={{
                                width: "100%"
                            }}
                        />
                    }
                >
                    <Typography
                        variant="h1"
                        component="h1"
                        sx={{
                            mt: 8,
                            mb: 4,
                            fontSize: "1.625rem",
                            fontWeight: "500"
                        }}
                    >
                        Faster:
                    </Typography>
                    <Box>
                        {MODA_FEATURES.FASTER.map(({ title, description }) => (
                            <Box mb={3} key={title}>
                                <Typography
                                    sx={{
                                        fontSize: "1.375rem",
                                        fontWeight: 500,
                                        mb: 2.5
                                    }}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: "Hind",
                                        lineHeight: "1.35",
                                        fontSize: "1.125rem"
                                    }}
                                >
                                    {description}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </ContentContainer>
                <ContentContainer
                    image={
                        <Box
                            component="img"
                            alt="Up arrow"
                            src={UpArrow}
                            sx={{
                                width: "100%"
                            }}
                        />
                    }
                >
                    <Typography
                        variant="h1"
                        component="h1"
                        sx={{
                            mt: 8,
                            mb: 4,
                            fontSize: "1.625rem",
                            fontWeight: "500"
                        }}
                    >
                        Simpler:
                    </Typography>

                    <Box>
                        {MODA_FEATURES.SIMPLER.map(({ title, description }) => (
                            <Box mb={3} key={title}>
                                <Typography
                                    sx={{
                                        fontSize: "1.375rem",
                                        fontWeight: 500,
                                        mb: 2.5
                                    }}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: "Hind",
                                        lineHeight: "1.35",
                                        fontSize: "1.125rem"
                                    }}
                                >
                                    {description}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </ContentContainer>
            </Box>

            <Box
                bgcolor="grey.A100"
                sx={{
                    pt: {
                        xs: 2,
                        md: 8
                    },
                    pb: {
                        xs: 2,
                        md: 14
                    }
                }}
            >
                <Layout.Content>
                    <Typography
                        sx={{
                            mb: {
                                xs: 2,
                                md: 6
                            },
                            fontSize: "1.875rem",
                            fontWeight: 500
                        }}
                    >
                        Our senior management team
                    </Typography>

                    <Grid container={true} spacing={4}>
                        {managementTeam.map((person) => (
                            <Grid key={person.name} item={true} xs={12} sm={6} sx={{ mb: 2 }}>
                                <PersonCard key={person.name} {...person} />
                            </Grid>
                        ))}
                    </Grid>
                </Layout.Content>
            </Box>
            <IntermediariesContact />
        </Layout>
    );
};

AboutUs.propTypes = {
    data: PropTypes.object.isRequired
};

export const query = graphql`
    query {
        timelineItems: allMarkdownRemark(
            filter: { fields: { sourceName: { eq: "about-us-timeline-items" } } }
            sort: { order: ASC, fields: [frontmatter___order] }
        ) {
            nodes {
                frontmatter {
                    title
                    backgroundPosition
                    image {
                        bgcolor
                        src {
                            publicURL
                        }
                    }
                }
                html
            }
        }
        managementTeam: allMarkdownRemark(
            filter: { fields: { sourceName: { eq: "about-us-management-team" } } }
            sort: { order: ASC, fields: [frontmatter___order] }
        ) {
            nodes {
                frontmatter {
                    name
                    title
                    links {
                        linkedIn
                    }
                    photo {
                        publicURL
                    }
                }
                html
            }
        }
    }
`;

export const Head = () => (
    <SEO
        title="ModaMortgages: About us"
        description="Smarter, faster, simpler. We’re ModaMortgages from Chetwood Financial, a modern kind of bank"
    />
);

export default AboutUs;
